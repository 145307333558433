












































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { sort } from '@/utils/sort';
import { nameFilter } from '@/utils/name_filter';
import {
  AdminAction, AdminActionType, AdminState, UserGetter, UserGetterType
} from '@/store/modules/admin/types';
import EditableListItem from '@/components/base/EditableListItem.vue';
import { Models } from '@mtap-smartcity/lib-api';

const admin = namespace('admin');

@Component({
  components: {
    EditableListItem,
  },
})
/**
 * @group Users Card
 * Users tab left panel - Users list
 */
export default class UsersCardLeftPanelUsers extends Vue {
  modifiedUser: Models.Bff.Auth.Users.Model | null = null;

  showDialog = false;

  dialog = false;

  deleteData: Models.Bff.Auth.Users.Model | null = null;

  key = 1;

  pending = false;

  @admin.State
  users!: AdminState['users']

  @admin.State
  selectedUser!: AdminState['selectedUser']

  @admin.State
  createUserFlag!: AdminState['createUserFlag']

  @admin.Action(AdminAction.SetSelectedUser)
  setSelectedUser!: AdminActionType['SET_SELECTED_USER'];

  @admin.Action(AdminAction.AddUserPlaceholder)
  addUserPlaceholder!: AdminActionType['ADD_USER_PLACEHOLDER'];

  @admin.Action(AdminAction.RemoveUserPlaceholder)
  removeUserPlaceholder!: AdminActionType['REMOVE_USER_PLACEHOLDER'];

  @admin.Action(AdminAction.CreateUser)
  createUser!: AdminActionType['CREATE_USER'];

  @admin.Action(AdminAction.UpdateUser)
  updateUser!: AdminActionType['UPDATE_USER'];

  @admin.Action(AdminAction.DeleteUser)
  deleteUser!: AdminActionType['DELETE_USER'];

  @admin.Action(AdminAction.SetCreateUserFlag)
  SetCreateUserFlag!: AdminActionType['SET_CREATE_USER_FLAG'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  // sort and filter users using search field
  filteredUsers(searchPhrase: string) {
    const sortedUsers = [...this.users].sort((a, b) => sort(a.username, b?.username));
    return sortedUsers.filter((u) => nameFilter(u.username, searchPhrase));
  }

  // check if the user from users list is being edited
  isModifiedUser(userId: string) {
    return !!this.modifiedUser && this.modifiedUser.id === userId;
  }

  // check if the user from users list is selected (clicked)
  isSelectedUser(userId: string) {
    return !!this.selectedUser && this.selectedUser.id === userId;
  }

  // check if the user object is a placeholder user object
  // (an object added to the list of users in the store, which serves as an input element for creating a new user)
  // in this case it doesn't have an id
  isPlaceholderUser(user: Models.Bff.Auth.Users.Model) {
    return !user.id;
  }

  deleteItem(user: Models.Bff.Auth.Users.Model) {
    this.deleteUser(user.id as string);
  }

  eventHandlers(user: Models.Bff.Auth.Users.Model) {
    return {
      select: () => this.selectUser(user),
      // edit: () => this.editUser(user),
      discard: this.discard,
      save: this.save,
      modalAction: () => {
        this.dialog = true;
        this.deleteData = user;
      },
    };
  }

  closeModal() {
    this.dialog = false;
  }

  deleteModal() {
    // eslint-disable-next-line
    this.deleteData && (this.deleteItem(this.deleteData));
    this.deleteData = null;
    this.dialog = false;
  }

  selectUser(user: Models.Bff.Auth.Users.Model) {
    // disallow clicking on a user (selecting)
    // when some action is pending or if some user is currently being edited
    if (this.createUserFlag) {
      this.SetCreateUserFlag();
    }
    if (this.pending
      || this.modifiedUser) return;
    this.setSelectedUser(user);
  }

  editUser(user: Models.Bff.Auth.Users.Model) {
    this.setSelectedUser(null);
    // copy the user so that the original object is not modified
    this.modifiedUser = { ...user };
  }

  save() {
    // if modifiedUser is a placeholder object for a new user, create a new user
    // (it doesn't have an id in this case)
    if (!this.isPlaceholderUser(this.modifiedUser!)) {
      // else update the user that's been edited
      this.updateUser(this.modifiedUser!)
        .catch(() => {
          // force component rerender if updating failed
          // so that the user is displayed with correct, not modified name
          this.key += 1;
        })
        .finally(() => {
          this.modifiedUser = null;
        });
    }
  }

  discard() {
    if (this.isPlaceholderUser(this.modifiedUser!)) {
      this.removeUserPlaceholder();
    } else {
      // force component rerender if the app user wants to discard changes
      // so that the user is displayed with correct, not modified name
      this.key += 1;
    }
    this.modifiedUser = null;
  }

  addUser() {
    this.SetCreateUserFlag();
  }

  mounted() {
    // select the frist user by default
    if (!this.selectedUser && this.users.length) {
      const user = this.users.sort((a, b) => sort(a.username, b.username))[0];
      this.setSelectedUser(user);
    }
  }

  destroyed() {
    this.setSelectedUser(null);
    this.removeUserPlaceholder();
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'users');
    return getPermissions.write;
  }

  get permissionCheckDelete() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'users');
    return getPermissions.delete;
  }
}
